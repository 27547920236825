<template>
  <div class="members-admin container-fluid container-fixed-lg bg-white app-table-container row" style="margin-top:20px;">
    <div class="col-md-6" style="margin-top:28px;">
      <ValidationObserver ref="form_edit_entry" v-slot="{ passes, valid, validated }">
        <form novalidate autocomplete="off" v-on:submit.prevent="passes(saveMember)">
          <div class="m-t-12 row justify-content-end" style="margin-right:5px;margin-bottom: 15px;">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
              {{ $t('common.cancel') }}
            </button>

            <button :disabled="!valid && validated" class="btn btn-complete m-r-10" type="submit">
              {{ $t('common.update_btn') }}
            </button>
          </div>

          <div class="m-t-12 row card-body">
            <table class="table dataTable app-table no-footer">
              <thead>
                <tr>
                  <th colspan="4">{{ $t('members.members_basic') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.detail') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="1" class="center">
                    <AppInput
                      rules="required|no_space"
                      input-style="normal"
                      name="sei"
                      type="string"
                      v-model="entryToSave.sei"
                    />
                  </td>
                  <td colspan="2" class="center">
                    <AppInput
                      rules="required|no_space"
                      input-style="normal"
                      name="mei"
                      type="string"
                      v-model="entryToSave.mei"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.furigana') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="1" class="center">
                    <AppInput
                      rules="required|katakana"
                      input-style="normal"
                      name="sei_kana"
                      type="string"
                      v-model="entryToSave.sei_kana"
                    />
                  </td>
                  <td colspan="2" class="center">
                    <AppInput
                      rules="required|katakana"
                      input-style="normal"
                      name="mei_kana"
                      type="string"
                      v-model="entryToSave.mei_kana"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('users.phone') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="3" class="center">
                    <AppInput
                      rules="required|phone"
                      input-style="normal"
                      name="phone"
                      type="number-length:11"
                      v-model="entryToSave.phone"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">{{ $t('users.email') }}</td>
                  <td colspan="3" class="center">
                    <AppInput
                      :disabled="true"
                      input-style="normal"
                      name="email"
                      type="email"
                      v-model="entryToSave.email"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.gender') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="3" class="center select">
                    <AppSelect
                      rules="required"
                      input-style="normal"
                      name="gender"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="options_user_gender"
                      :value="entryToSave.gender"
                      v-model="entryToSave.gender"
                      :value_empty="false"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.birthday') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>

                  <td colspan="3" class="center">
                    <AppDatePicker
                      rules="required"
                      input-style="normal"
                      name="birthday"
                      v-model="entryToSave.birthday"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.post_code') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="3" class="center">
                    <AppInput
                      rules="required|min:7"
                      input-style="normal"
                      name="postal_code"
                      type="number-length:7"
                      v-model="entryToSave.postal_code"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.prefecture') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="3" class="center select">
                    <AppSelect
                      :onChange="(id) => changePrefecture(id)"
                      input-style="normal"
                      rules="required"
                      name="prefecture_id"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="options_prefecture"
                      :value="entryToSave.prefecture_id"
                      v-model="entryToSave.prefecture_id"
                      :value_empty="false"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">{{ $t('members.cities') }}</td>
                  <td colspan="3" class="center select">
                    <AppSelect
                      input-style="normal"
                      name="city_id"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="options_cities"
                      :value="entryToSave.city_id"
                      v-model="entryToSave.city_id"
                      :value_empty="false"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.street') }}
                  </td>
                  <td colspan="3" class="center">
                    <AppInput
                      input-style="normal"
                      name="street"
                      type="string"
                      v-model="entryToSave.street"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">{{ $t('members.building') }}</td>
                  <td colspan="3" class="center">
                    <AppInput
                      input-style="normal"
                      name="building"
                      type="string"
                      v-model="entryToSave.building"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.user_type') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>
                  <td colspan="3" class="center select">
                    <AppSelect
                      rules="required"
                      input-style="normal"
                      name="user_type"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="options_user_type"
                      :value="entryToSave.user_type"
                      v-model="entryToSave.user_type"
                      :value_empty="false"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('members.introducer_name')}}
                  </td>
                  <td colspan="3" class="center">
                    <AppInput
                      input-style="normal"
                      name="introducer_name"
                      type="string"
                      v-model="entryToSave.introducer_name"
                    />
                  </td>
                </tr>

                <tr>
                  <td class="v-align-middle text-left">
                    {{ $t('users.active') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </td>

                  <td colspan="3" class="center select">
                    <AppSelect
                      rules="required"
                      input-style="normal"
                      name="active"
                      boundaryClass="m-auto"
                      :options="{containerCssClass: 'z-index-0'}"
                      :options-data="status"
                      :value="entryToSave.active"
                      v-model="entryToSave.active"
                      :value_empty="false"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>

        <div class="m-t-12" style="margin-top:20px">
          <div class="card-body">
            <table class="table dataTable app-table no-footer">
              <thead>
                <tr>
                  <th colspan="4">{{ $t('members.history_booking') }}</th>
                </tr>
              </thead>

              <tbody>
                <template v-for="(history, index) in histories">
                  <tr>
                    <td class="v-align-middle">
                      <span>
                        <p class="app-table-p app-cell-tooltip" :data-original-title="getNameTourHistory(history)">
                          <a
                            class="a-link-cell"
                            target="_blank"
                            v-bind:href="history.tour_order != null ? history.tour_order.public_url : '#'"
                          >
                            {{ getNameTourHistory(history) }}
                          </a>
                        </p>
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="m-t-12 justify-content-center">
          <button
            type="button"
            class="btn btn-complete m-r-10"
            @click="getAllHistory"
            style="float:right;margin-right:15px;margin-top:5px;"
          >
            {{ $t('members.loadmore') }}
          </button>
        </div>
      </ValidationObserver>
    </div>

    <div class="col-md-6" style="margin-top:88px;">
      <div class="container-fluid container-fixed-lg bg-white app-table-container">
        <div class="card card-transparent">
          <div class="card-body">
            <div class="dataTables_wrapper no-footer bg-white">
              <div class="table-responsive sm-m-b-15">
                <table class="table dataTable app-table no-footer" style="">
                  <thead style="border-bottom: 1px solid #a5a5a5;">
                    <tr>
                      <th colspan="4">{{ $t('members.history') }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicTable
        ref="table"
        :table-name="tableName"
        :setting-columns="columns"
        :actions="actions"
        :filters.sync="filters"
        :search="search"
        :setting-apis="apis"
      /></BasicTable>
    </div>

    <Modal
      modal-class="slide-up modal_form"
      ref="modal_form_support"
      size="lg"
      :on-hide="clearEntryToSave"
      :modal-header-title="title"
      @click="confirmClose"
    >
      <template v-slot:container>
        <ValidationObserver ref="form_support" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" v-on:submit.prevent="passes(saveSupportHistory)">
            <div class="modal-body row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label input-label p-0">
                    {{ $t('members.datetime') }}
                    <span class="color-active app-text-bold"> ＊</span>
                  </label>

                  <AppDateTimePicker
                    value-zone="Asia/Tokyo"
                    clear="0"
                    input-style="normal"
                    rules="required"
                    class="form-group datetime-custom"
                    format="YYYY/MM/DD H:i"
                    width="100%"
                    v-model="entrySupport.datetime"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <AppSelect
                  rules="required"
                  :label="$t('members.admin_name')"
                  input-style="normal"
                  name="admin_id"
                  boundaryClass="m-auto"
                  :options="{containerCssClass: 'z-index-0'}"
                  :options-data="options_users"
                  :value="entrySupport.admin_id"
                  v-model="entrySupport.admin_id"
                  :value_empty="false"
                />
              </div>

              <div class="col-md-12">
                <AppText
                  rules="required|max:2000"
                  input-style="normal"
                  type="text"
                  name="content"
                  :label="$t('members.content')"
                  v-model="entrySupport.content"
                />
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                @click="confirmClose"
              >
                {{ $t('members.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ entrySupport.id ? $t('common.update_btn') : $t('common.create_btn')}}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <ConfirmModal
      ref="modal_delete_entry"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>

    <ConfirmModal
      ref="modal_cancle"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntryModal">
    </ConfirmModal>
  </div>
</template>
<script>
  import BasicTable from '@components/_common/List/BasicTable';
  import datetime from 'vuejs-datetimepicker';

  export default {
    data() {
      return {
        title: "",
        tableName: this.$t('users.admin user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.SUPPORT_LIST,
            params: {"filters[member_id][equal]": this.$route.params.id}
          },
        },
        actions: {
          createEntry: this.createEntry,
          others: [
          {
            showCondition: entry => entry.content != null,
            label: this.$t('members.content'),
            click: entry => this.editEntry(entry),
            contentHtml: entry => "<p class='app-table-p app-cell-tooltip' data-original-title='"+this.htmlencode(entry.content)+"'><a href='#' class='a-link-cell'>" + this.htmlencode(entry.content) + "</a></p>",
          },
        ]
        },
        search: {
          searchAll: false,
          reset: false,
        },
        filters: {},
        entryToSave: {
          user_type: 1,
        },
        cloneEntryToSave: {},
        entrySupport: {
          admin_id: this.$auth.user().id,
          member_id: this.$route.params.id,
          datetime: "",
        },
        cloneEntrySupport: {},
        options_prefecture: [],
        options_cities: [],
        options_users: [],
        long: [],
        options_user_type: [
          {
            id: 0,
            name: this.$t('members.members_0')
          },
          {
            id: 1,
            name: this.$t('members.members_1')
          },
        ],
        options_user_gender: [
          {
            id: 2,
            name: this.$t('members.gender_0')
          },
          {
            id: 1,
            name: this.$t('members.gender_1')
          },
        ],
        status: [
          {
            id: 0,
            name: this.$t('users.active_off')
          },
          {
            id: 1,
            name: this.$t('users.active_on')
          }
        ],
        histories: [],
        columns: [
          {name: 'datetime', label: this.$t('members.datetime'), class: 'min-w-80 text-center'},
          {
            label: this.$t('members.admin_name'),
            class: 'min-w-80',
            computedHtml: (entry) => {
              if (entry.admin !=  null) {
                return "<p class='app-table-p app-cell-tooltip' data-original-title='"+this.htmlencode(entry.admin.name)+"'>" + this.htmlencode(entry.admin.name) + "</p>";
              }
            },
          },
        ],
      }
    },
    components: {BasicTable, datetime},
    mounted()
    {
      let params = this.$route.params;

      if (params.id) {
        this.getMemberDetail(params.id);
        this.getAlias();
        this.getAdminSelect();
        this.getBooking(5);
      }
    },
    methods: {
      getNameTourHistory(history)
      {
        let tour_name = '';
        let start_date = '';
        let end_date = '';

        if (history.tour_order != null && history.tour_order.name != null) {
          tour_name = _.get(history.tour_order, 'name');
        }

        if (history.start_date != null) {
          start_date = history.start_date;
        }

        if (history.end_date != null) {
          end_date = history.end_date;
        }

        let string  = history.start_date != null || history.end_date != null ? ' ~ ' : '';

        return this.$t('members.format') + ' ' + start_date + string + end_date + ' ' + tour_name;
      },
      htmlencode(str)
      {
        var p = document.createElement("p");
        p.textContent = str;
        var converted = p.innerHTML;

        return converted;
      },
      async getBooking(number)
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.BOOKING_LIST + '?filters[b_tour_order_id][not_equal]=null', {
          sortType: "asc",
          page: 1,
          perPage: number,
          "filters[member_id][equal]": this.$route.params.id,
          "filters[status][equal]": this.$consts.INPUT.BOOKING_COMPLETE,
        });

        if (!res.hasErrors()) {
          this.histories = res.data.data;

          this.$nextTick(() => {
            $.each($('.app-cell-tooltip'), (index, el) => {
              if (el.offsetWidth < el.scrollWidth) {
                $(el).tooltip({boundary: 'window'})
              }
            })
          });
        }
      },
      async getAdminSelect()
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.USERS_SELECT, {
          sortType: "asc",
        });

        if (!res.hasErrors()) {
          this.options_users = res.data;
        }
      },
      async changePrefecture(id)
      {
        if (id != null) {
          let params = {
            sortType: "asc",
            sortBy: "code",
          };
          params[`filters[prefecture_id][equal]`] = id;
          const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, params);

          if (!res.hasErrors()) {
            this.options_cities = res.data.data;
            this.options_cities.push(
              {
                id: 0,
                name: this.$t('common.no_select_all'),
              }
            );
          }
        }
      },
      async getAlias() {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES, {
          sortType: "asc",
          sortBy: "code",
        });

        if (!res.hasErrors()) {
          this.options_prefecture = res.data.data;
        }

        let params = {
          sortType: "asc",
          sortBy: "code",
        };

        params[`filters[prefecture_id][equal]`] = this.entryToSave.prefecture_id;
        const res1 = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, params);

        if (!res1.hasErrors()) {
          this.options_cities = res1.data.data;
          this.options_cities.push(
            {
              id: 0,
              name: this.$t('common.no_select_all'),
            }
          )
        }
      },
      async saveMember()
      {
        const res = await this.$request.patch(this.$consts.API.ADMIN.MEMBERS_EDIT(this.entryToSave.id), this.entryToSave);

        if (res.hasErrors()) {
          this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        } else {
          this.$appNotice.success(this.$t('common.msg update ok'));
        }
      },
      async saveSupportHistory()
      {
        if (this.entrySupport.id) {
          const res = await this.$request.patch(this.$consts.API.ADMIN.SUPPORT_EDIT(this.entrySupport.id), this.entrySupport);

          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg update ok'))
            this.$refs.modal_form_support.hide();
            this.$refs.table.getEntries();
            this.entrySupport = {
              admin_id: this.$auth.user().id,
              member_id: this.$route.params.id,
              datetime: "",
            }
          }
        } else {
          const res = await this.$request.post(this.$consts.API.ADMIN.SUPPORT_SAVE, this.entrySupport);

          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg create ok'))
            this.$refs.modal_form_support.hide();
            this.$refs.table.getEntries();

            this.entrySupport = {
              admin_id: this.$auth.user().id,
              member_id: this.$route.params.id,
              datetime: "",
            }
          }
        }
      },
      createEntry()
      {
        this.$refs.form_support.reset();
        const date = new Date();
        let now = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
        this.entrySupport.datetime = now;
        this.entrySupport.content = "";
        this.entrySupport.admin_id = this.$auth.user().id;

        this.$refs.modal_form_support.show();
      },
      editEntry(entry)
      {
        this.$refs.form_support.reset();
        this.entrySupport = _.cloneDeep(entry);
        this.cloneEntrySupport = _.cloneDeep(this.entrySupport);
        this.cloneEntrySupport.datetime = entry.datetime;
        this.$refs.modal_form_support.show();

        return false;
      },
      deleteEntryModal()
      {
        this.$router.push({name: this.$consts.ROUTES.ADMIN.MEMBERS});
      },
      confirmCancel()
      {
        this.$refs.modal_cancle.show();
      },
      deleteEntrySubmit()
      {
        this.$refs.modal_delete_entry.hide();
        this.$refs.modal_form_support.hide();
        this.$refs.form_support.reset();
      },
      getAllHistory()
      {
        this.getBooking(9999999999999999);
      },
      clearEntryToSave()
      {

      },
      confirmClose()
      {
        this.$refs.modal_delete_entry.show();
      },
      async getMemberDetail(id)
      {
        const res = await this.$request.get(this.$consts.API.ADMIN.MEMBERS_EDIT(id));

        if (!res.hasErrors()) {
          this.entryToSave = res.data.data;
          this.entryToSave.email_verified_at = this.entryToSave.user.email_verified_at;
          this.cloneEntryToSave = _.cloneDeep(this.entryToSave);
        } else {
          this.$appNotice.error(res.data?.error_msg);
          this.$router.push({name: this.$consts.ROUTES.ADMIN.MEMBERS});
        }
      },
      htmltrim(str)
      {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || "";
      }
    }
  }
</script>

<style>
  td.select {
    height: 70px;
  }
  td.center .form-group {
    margin-top: 10px;
  }
  .datetime-custom input {
    border-radius: 2px;
    height: 50px !important;
  }
  .modal_form .close{
    display: none;
  }
  .year-month-wrapper, .headers .days{
    background-color: #ffffff !important;
  }
  .year-month-wrapper .year,.year-month-wrapper .month {
    color: #6f7b8a !important;
  }
  .headers .days, .okButton {
    color: #6d5cae !important;
  }
  .nav-l, .nav-r {
    background-color: #ffffff !important;
    color: #6d5cae !important;
  }
  .members-admin .activePort {
    background: #dc3545 !important;
  }
  .members-admin .time-picker .showSelector{
    width: 200px !important;
    z-index: 9999;
  }
  .members-admin .time-picker .showSelector ul {
    width: 200px !important;
    z-index: 9999;
  }
  .members-admin .time-picker .showSelector li.active {
    background: #dc3545 !important;
  }
  .select2-container {
    z-index: 9999;
  }
</style>
